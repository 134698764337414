import React, { useEffect, useState } from "react";
import NormalButton from "../../components/NormalButton";
import NormalTable from "../../components/NormalTable";
import "../staff/styles.scss";
import "../users/styles.scss";
import './styles.scss'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, } from "react-redux";
import NoData from '../../components/noData';
import moment from "moment";
import { deleteDeeplinkById, getDeeplinksData, saveData } from "../../redux/reducers/deeplinkSlice";
import { successToast } from "../../utils";
import TooltipComponent from "../../components/tooltip";
import InputFieldNoValidation from "../../components/inputFieldNoValidation";


const Deeplinks = () => {

  let navigate = useNavigate();

  const dispatch = useDispatch();

  const data = useSelector((state) => state?.deeplinks?.data ?? []);

  const backupData = useSelector((state) => state?.deeplinks?.backUpData ?? []);


  const headerDetails = [
    { label: "S No" },
    { label: "Module" },
    { label: "Url" },
    { label: "Actions" },
  ];

  useEffect(() => {
    getData()
  }, []);

  const getData = () => {
    dispatch(getDeeplinksData());
  }

  const onClickUrl = (_id) => {
    const URL = `https://api.zenitapp.in/api/v1/deeplinks/deeplink?id=${_id}`
    navigator.clipboard.writeText(URL);
    successToast('URL copied to clipboard')
  }

  const onClickDelete = (data) => {
    deleteDeeplinkById(data?._id).then(res => {
      getData();
      successToast('Deeplink deleted successfully.')
    }).catch(_err => { })
  }

  const onChangeSearch = (e) => {
    if(e?.target?.value == ''){
      dispatch(saveData(backupData))
    }else{
      let filter = backupData?.filter(it => {
        let module = `${it?.module}${it?.params?.category ? ' - ' + it?.params?.category : ''}${it?.params?.trackName ? ' - ' + it?.params?.trackName : ''}`;
        return module?.toLowerCase()?.includes(e?.target?.value);
      })
      dispatch(saveData(filter))
    }
  }


  return (
    <div className="users-main-container">
      <div className="users-header-container">
        <p className="users-title">Deeplinks</p>

      </div>

      <div className="customer-care-search-container ">
        <div className="staff-search-input">
          <InputFieldNoValidation
            leftIcon
            searchIcon
            onChange={onChangeSearch}
            placeholder="Search" />
        </div>
        <div className="ms-3">
          <NormalButton
            label="ADD DEEPLINK "
            cancelBtn
            onClick={() => navigate("/main/add-deeplink")}
          />
        </div>
      </div>
      <div className="users-table-container">
        <NormalTable
          headerDetails={headerDetails}
        >
          {data.length > 0 ? (
            <>
              {data.map((list, index) => {
                let {
                  _id,
                  module,
                  params,
                } = list;
                return (
                  <tr key={_id}>
                    <td>{index + 1}</td>
                    <td>{`${module}${params?.category ? ' - ' + params?.category : ''}${params?.trackName ? ' - ' + params?.trackName : ''}`}</td>
                    <td className="url-text" onClick={() => onClickUrl(_id)}>URL</td>
                    <td align="center">
                      <TooltipComponent
                        onClickDelete={() => onClickDelete(list)} />
                    </td>
                  </tr>
                );
              })}
            </>
          ) : <tr>
            <td colSpan={8} className="text-center">
              <NoData />
            </td>
          </tr>
          }
        </NormalTable>
      </div>



    </div>
  );
};

export default Deeplinks;
