const routes = [
  {
    layout: "AuthLayout",
    path: "/auth",
    isSecure: false,
    childrens: [
      {
        screen: "Login",
        childPath: "/login",
      },
      {
        screen: "ForgotPassword",
        childPath: "/forgot-password",
      },
      {
        screen: "VerifyOtp",
        childPath: "/verify-otp",
      },
      {
        screen: "ResetPassword",
        childPath: "/reset-password",
      },
    ],
  },
  {
    layout: "MainLayout",
    path: "/main",
    isSecure: false,
    childrens: [
      {
        screen: "Dashboard",
        childPath: "/dashboard",
        permissionKey:"Dashboard"
      },
      {
        screen: "Users",
        childPath: "/users",
        permissionKey:"Users"
      },
      {
        screen: "EditUsers",
        childPath: "/edit-users",
        permissionKey:"Users"
      },
      {
        screen: "Payment",
        childPath: "/payment",
        permissionKey:"Payment"
      },
      {
        screen: "ManageSubscriptions",
        childPath: "/manage-subscriptions",
        permissionKey:"Payment"
      },
      {
        screen: "EditFreeFeature",
        childPath: "/edit-free-feature",
        permissionKey:"Payment"
      },
      {
        screen: "EditPremiumFeature",
        childPath: "/edit-premium-feature",
        permissionKey:"Payment"
      },
      {
        screen: "AddSubscription",
        childPath: "/add-subscription",
        permissionKey:"Payment"
      },
      {
        screen: "Badges",
        childPath: "/badges",
        permissionKey:"Badges"
      },
      {
        screen: "EditBadge",
        childPath: "/edit-badge",
        permissionKey:"Badges"
      },
      {
        screen: "CreateBadge",
        childPath: "/add-badge",
        permissionKey:"Badges"
      },
      {
        screen: "Content",
        childPath: "/content",
        permissionKey:"Content"
      },
      {
        screen: "SelfCareContent",
        childPath: "/self-care-content",
        permissionKey:"Content"
      },
      {
        screen: "EditSelfCare",
        childPath: "/self-care-content/:id",
        permissionKey:"Content"
      },
      {
        screen: "EditSelfCare",
        childPath: "/add-self-care",
        permissionKey:"Content"
      },
      {
        screen: "LearnTutorial",
        childPath: "/learn-tutorial",
        permissionKey:"Content"
      },
      {
        screen: "AddTutorial",
        childPath: "/learn-tutorial/:id",
        permissionKey:"Content"
      },
      {
        screen: "AddTutorial",
        childPath: "/add-tutorial",
        permissionKey:"Content"
      },
      {
        screen: "CustomerCare",
        childPath: "/customer-care",
        permissionKey:"CustomerCare"
      },
      {
        screen: "EditTicket",
        childPath: "/customer-care/:id",
        permissionKey:"CustomerCare"
      },
      {
        screen: "PrivacyTerms",
        childPath: "/terms&privacy",
        permissionKey:"PrivacyTerms"
      },
      {
        screen: "EditTerms",
        childPath: "/edit-terms",
        permissionKey:"PrivacyTerms"
      },
      {
        screen: "EditTerms",
        childPath: "/edit-privacy",
        permissionKey:"PrivacyTerms"
      },
      {
        screen: "Staff",
        childPath: "/staff",
        permissionKey:"Staff"
      },
      {
        screen: "AddStaff",
        childPath: "/add-staff",
        permissionKey:"Staff"
      },
      {
        screen: "AddStaff",
        childPath: "/staff/:id",
        permissionKey:"Staff"
      },
      {
        screen: "ProfileSettings",
        childPath: "/profile-settings",
      },
      {
        screen: "Notification",
        childPath: "/notification",
      },
      {
        screen: "UserNotification",
        childPath: "/user-notification",
        permissionKey:"Staff"
      },
      {
        screen: "AddUserNotification",
        childPath: "/add-notification",
        permissionKey:"Staff"
      },
      {
        screen: "CalendarPreferences",
        childPath: "/app-configs",
        permissionKey:"Staff"
      },
      {
        screen: "DeletedUsers",
        childPath: "/delete-user",
        permissionKey:"Staff"
      },
      {
        screen: "Deeplinks",
        childPath: "/deeplinks",
        permissionKey:"Staff"
      },
      {
        screen: "AddDeeplink",
        childPath: "/add-deeplink",
        permissionKey:"Staff"
      },
    ],
  },
  {
    init: "/auth/login",
    path: "/",
  },
  {
    init: "/",
    path: "/auth",
  },
  {
    init: "/main/dashboard",
    path: "/main",
  },
  {
    component: "Page404",
    path: "*",
  },
  {
    component: "NoPermission",
    path: "/no-permission",
  },
];

export default routes;
