import React, { useState } from "react";
import "./styles.scss";
import "../login/styles.scss";
import "../editUsers/styles.scss";
import assets from "../../assets";
import NormalButton from "../../components/NormalButton";
import { useNavigate } from "react-router-dom";
import TextAreaInput from "../../components/TextArea";
import { useForm } from "react-hook-form";
import { dropDownValidation, errorToast, successToast } from "../../utils";
import CustomController from "../../components/customController";
import NormalSelect from "../../components/NormalSelect";
import { createDeeplink, getSelfTracksByCategory } from "../../redux/reducers/deeplinkSlice";

const AddDeeplink = () => {
  let navigate = useNavigate();

  const [selectedDeeplinkModule, setSelectedDeeplinkModule] = useState(null);
  const [deeplinkModuleData, setDeeplinkModuleData] = useState([
    {
      label: 'Privacy Policy',
      value: 'privacy',
      navigation: 'PrivacyPolicy',
      params: {
        title: 'Privacy Policy',
        type: 'privacy'
      }
    },
    {
      label: 'Terms and conditions',
      value: 'tnc',
      navigation: 'PrivacyPolicy',
      params: {
        title: 'Terms of Service',
        type: 'terms'
      }
    },
    {
      label: 'Subscribe',
      value: 'subscribe',
      navigation: 'Subscribe',
      params: {
        isLoggedIn: true
      }
    },
    {
      label: 'Analytics',
      value: 'analytics',
      navigation: 'Analytics',
      params: {
        isLoggedIn: true
      }
    },
    {
      label: 'Tutorials',
      value: 'Learn',
      navigation: 'Learn',
      params: {
        isLoggedIn: true
      }
    },
    {
      label: 'Bookmark',
      value: 'bookmark',
      navigation: 'Bookmark',
      params: {
        isLoggedIn: true
      }
    },
    {
      label: 'Ratings',
      value: 'rateUs',
      navigation: 'RateUs',
      params: {
        isLoggedIn: true
      }
    },
    {
      label: 'ReachOut',
      value: 'reachOut',
      navigation: 'ReachOut',
      params: {
        isLoggedIn: true
      }
    },
    {
      label: 'Achievements',
      value: 'achievements',
      navigation: 'Achievements',
      params: {
        isLoggedIn: true
      }
    },
    {
      label: 'FocusTime',
      value: 'focusTime',
      navigation: 'FocusTime',
      params: {
        isLoggedIn: true
      }
    },
    {
      label: 'CreateTask',
      value: 'createTask',
      navigation: 'CreateTask',
      params: {
        isLoggedIn: true
      }
    },
    {
      label: 'TaskList',
      value: 'taskList',
      navigation: 'TaskList',
      params: {
        isLoggedIn: true
      }
    },
    {
      label: 'Calendars',
      value: 'calendars',
      navigation: 'Calendars',
      params: {
        isLoggedIn: true
      }
    },
    {
      label: 'Priority Wizard',
      value: 'priorityWizard',
      navigation: 'PriorityWizard',
      params: {
        isPremium: true,
        isLoggedIn: true
      }
    },
    {
      label: 'Self Care Category',
      value: 'selfCareCategory',
      navigation: 'ContentDetails',
      params: {
        category: '',
        isLoggedIn: true
      }
    },
    {
      label: 'Self Care Track',
      value: 'selfCareTrack',
      navigation: 'AudioPlayer',
      params: {
        category: '',
        trackId: '',
        isLoggedIn: true
      }
    },
  ]);


  const [selfCareCategoryData, setSelfCareCategoryData] = useState([
    {
      label: "Uplift",
      value: "Uplift",
    },
    {
      label: "Centering",
      value: "Centering",
    },
    {
      label: "Meditation",
      value: "Meditation",
    },
    {
      label: "Stretch",
      value: "Stretch",
    },
    {
      label: "Sleep Stories",
      value: "Sleep Stories",
    },
    {
      label: "Morning Minute",
      value: "Morning Minute",
    }
  ]);

  const [selectedSelfCareCategory, setSelectedSelfCareCategory] = useState(null);

  const [selfCareTrackData, setSelfCareTrackData] = useState([]);
  const [selectedSelfCareTrackId, setSelectedSelfCareTrackId] = useState(null);

  const onSubmit = (data) => {

    let request = {
      ...data,
      ...selectedDeeplinkModule,
      module: selectedDeeplinkModule["label"]
    }

    console.log(request, 'request');
    

    delete request["label"]
    delete request["value"];
    delete request["selfCareCategory"];
    delete request["selfCareTrackId"];


    createDeeplink(request).then(res => {
      successToast("Deeplink created successfully");
      navigate(-1)
    }).catch(_err => {
    })

  };

  const onSelectSelfCareCategory = (e) => {
    setSelectedSelfCareCategory(e);
    if(selectedDeeplinkModule?.value == 'selfCareTrack'){
      getTracksByCategory(e?.value)
    }
    setSelectedDeeplinkModule({
      ...selectedDeeplinkModule,
      params: {
        category: e?.value,
        isLoggedIn: true
      }
    })
  }

  const onSelectSelfCareTrackId = (e) => {
    setSelectedSelfCareTrackId(e);
    let item = selfCareTrackData?.filter(it => it?.value == e?.value)[0]
    setSelectedDeeplinkModule({
      ...selectedDeeplinkModule,
      navigation: selectedDeeplinkModule?.params?.category == 'Strech' ? 'VideoPlayerScreen' : 'AudioPlayer',
      params: {
        ...selectedDeeplinkModule?.params,
        trackId: item?.value,
        trackName: item?.label
      }
    })
  }

  const getTracksByCategory = (category) => {
      getSelfTracksByCategory(category).then(res => {
        console.log(res);

        let item = [...res?.data]

        let mapData = item.map(it => {
          return {
            label: it?.title,
            value: it?._id
          }
        })
        
        setSelfCareTrackData(mapData);

      }).catch(_err => {})
  }

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },

  } = useForm({ mode: "onBlur" });

  return (
    <>
      <div className="edit-user-container">
        <div className="back-to-user-container">
          <img
            src={assets.Icons.left_icon}
            alt="left-icon"
            className="left-icon"
          />
          <span
            className="back-to-user"
            onClick={() => navigate("/main/deeplinks")}
          >
            Back to Deeplinks
          </span>
        </div>
        <div className="edit-user ">
          <p className="edit-user-details">{`Create new Deeplink`}</p>
          <div className="d-flex">
            <form className="col-md-3 d-flex" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-12">

                <div className="">
                  <label className="login-input-label">Module</label>
                  <CustomController
                    name="module"
                    control={control}
                    data={selectedDeeplinkModule}
                    error={errors.module}
                    register={register("module", dropDownValidation('Module'))}
                    render={({ field: { onChange } }) => {
                      return (
                        <NormalSelect
                          options={deeplinkModuleData}
                          borderGreen
                          disableButtons={true}
                          value={selectedDeeplinkModule}
                          error={errors.module}
                          onChange={(e) => {
                            let item = deeplinkModuleData.filter(it => it?.value == e?.value)[0];
                            setSelectedDeeplinkModule(item);
                            onChange(e?.value);
                          }}
                        />

                      );
                    }}
                  />
                </div>{" "}
                {
                  (selectedDeeplinkModule?.value == 'selfCareCategory' || selectedDeeplinkModule?.value == 'selfCareTrack') &&
                  <div className="">
                    <label className="login-input-label">Self Care Category</label>
                    <CustomController
                      name="selfCareCategory"
                      control={control}
                      disableButtons={true}
                      data={selectedSelfCareCategory}
                      error={errors.selfCareCategory}
                      register={register("selfCareCategory", dropDownValidation('Self Care Category'))}
                      render={({ field: { onChange } }) => {
                        return (
                          <NormalSelect
                            options={selfCareCategoryData}
                            borderGreen
                            disableButtons
                            value={selectedSelfCareCategory}
                            error={errors.selfCareCategory}
                            onChange={(e) => {
                              onSelectSelfCareCategory(e)
                              onChange(e?.value);
                            }}
                          />

                        );
                      }}
                    />
                  </div>
                }
                {
                  (selectedSelfCareCategory?.value && selectedDeeplinkModule?.value == 'selfCareTrack') &&
                  <div className="">
                    <label className="login-input-label">Self Care Track Id</label>
                    <CustomController
                      name="selfCareTrackId"
                      control={control}
                      disableButtons={true}
                      data={selectedSelfCareTrackId}
                      error={errors.selfCareTrackId}
                      register={register("selfCareTrackId", dropDownValidation('Track Id'))}
                      render={({ field: { onChange } }) => {
                        return (
                          <NormalSelect
                            options={selfCareTrackData}
                            borderGreen
                            disableButtons
                            value={selectedSelfCareTrackId}
                            error={errors.selfCareTrackId}
                            onChange={(e) => {
                              onSelectSelfCareTrackId(e);
                              onChange(e?.value);
                            }}
                          />

                        );
                      }}
                    />
                  </div>
                }
                <div className="add-btn-container">
                  <div className="edit-cancel-btn">
                    <NormalButton
                      label=" CANCEL"
                      cancelBtn
                      onClick={() => navigate("/main/deeplinks")}
                    />
                  </div>
                  <div className="edit-update-btn">
                    <NormalButton label={"CREATE"} mainBg />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>


    </>
  );
};

export default AddDeeplink;