import { createSlice } from "@reduxjs/toolkit";
import request from "../../services";
import Exported from "../../services/endpoints";

export const deeplinkSlice = createSlice({
    name: "deeplinks",
    initialState: {
        data: [],
        backUpData: [],
    },
    reducers: {
        saveData: (state, action) => {
            state.data = action.payload;
        },
        saveBackUpData: (state, action) => {
            state.backUpData = action.payload;
        }
    }
});


export const getDeeplinksData = () => async (dispatch) => {
    request({
        url: Exported.Endpoints.GET_ALL_DEEPLINKS,
        method: Exported.APIMethods.GET
    }).then(res => {
       dispatch(saveData(res?.data?.data));
       dispatch(saveBackUpData(res?.data?.data));
    }).catch(_err => {
    })
}

export const createDeeplink = (data) => {
    return new Promise((resolve, reject) => {
        request({
            url: Exported.Endpoints.CREATE_DEEPLINKS,
            method: Exported.APIMethods.POST,
            data
        }).then(res => {
            resolve(res)
        }).catch(_err => {
            reject(_err)
        })
    })
    
}

export const deleteDeeplinkById = (_id) => {

    return new Promise((resolve, reject)=>{
        request({
            url: `${Exported.Endpoints.DELETE_DEEPLINKS}${_id}`,
            method: Exported.APIMethods.DELETE,
        }).then(res => {
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const getSelfTracksByCategory = (category) => {

    return new Promise((resolve, reject)=>{
        request({
            url: `${Exported.Endpoints.GET_TRACKS_BY_ID}${category}`,
            method: Exported.APIMethods.GET,
        }).then(res => {
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}


export const { saveData, saveBackUpData } = deeplinkSlice.actions;

export default deeplinkSlice.reducer;
