import './styles.scss'
import React from "react";
import Lottie from "lottie-react";
import { Switch, Tooltip } from 'antd';
import ExportedData from "../../assets";

const TooltipComponent = (
    {
        onClickDelete,
        onClickEdit,
        onClickReactivate,
    }
) => {

    return (
        <div className='tooltip-container'>
            <Tooltip style={{
                backgroundColor: 'white'
            }} title={<div style={{
                backgroundColor: 'white'
            }}>
                {onClickReactivate ?
                <div onClick={() => onClickReactivate()} className="edit-icon-container">
                <img
                    src={ExportedData.Icons.edit}
                    className="edit-icon" />
                    <span className="tooletip_icon">Reactivate</span>
                </div>
              :
              <>
           
                {onClickEdit && <div onClick={() => onClickEdit()} className="edit-icon-container">
                    <img
                        src={ExportedData.Icons.edit}
                        className="edit-icon" />
                    <span className="tooletip_icon">Edit</span>
                </div>}
                {onClickEdit && <div className="horizontal-line" /> }
                <div onClick={() => onClickDelete()} className="icon-container">
                    <img
                        src={ExportedData.Icons.delete}
                        className="edit-icon" />
                    <span className="tooletip_icon">Delete</span>
                </div>
              </>
            }
            </div>} placement="bottomRight">
                <label className="tooletip_lable">...</label>
            </Tooltip>
        </div>
    )

}


export default TooltipComponent;

