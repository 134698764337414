import React, { useEffect, useState } from "react";
import "./styles.scss";
import assets from "../../assets";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveRememberMe, saveUserData } from "../../redux/reducers/authSlice";
import { getSideBarPermissions } from "../../utils";

const Sidebar = () => {
  const [navLink] = useState([
    {
      to: "/main/dashboard",
      label: "Dashboard",
      Icon: assets.Icons.dashboard,
      id: "1",
      permissionKey: "Dashboard"
    },
    {
      to: "/main/users",
      label: "Users",
      Icon: assets.Icons.users,
      id: "2",
      permissionKey: "Users"
    },
    {
      to: "/main/payment",
      label: "Payment",
      Icon: assets.Icons.payment,
      id: "3",
      permissionKey: "Payment"
    },
    {
      to: "/main/badges",
      label: "Badges",
      Icon: assets.Icons.dashboard,
      id: "4",
      permissionKey: "Badges"
    },
    {
      to: "/main/content",
      label: "Content",
      Icon: assets.Icons.content,
      id: "5",
      permissionKey: "Content"
    },
    {
      to: "/main/customer-care",
      label: "Customer Care",
      Icon: assets.Icons.customer_care,
      id: "6",
      permissionKey: "CustomerCare"
    },
    {
      to: "/main/terms&privacy",
      label: "Terms & Privacy",
      Icon: assets.Icons.terms_policy,
      id: "7",
      permissionKey:"PrivacyTerms"
    },
    {
      to: "/main/staff",
      label: "Staff",
      Icon: assets.Icons.staff,
      id: "8",
      permissionKey:"Staff"
    },
    {
      to: "/main/profile-settings",
      label: "Profile Settings",
      Icon: assets.Icons.settings,
      id: "9"
    },
    {
      to: "/main/user-notification",
      label: "User Notification",
      Icon: assets.Icons.notificationIcon,
      id: "10",
      permissionKey: "UserNotification"
    },
    {
      to: "/main/deeplinks",
      label: "Deeplinks",
      Icon: assets.Icons.link,
      id: "11",
      permissionKey: "Staff"
    },
    {
      to: "/main/app-configs",
      label: "App Config",
      Icon: assets.Icons.calendar_sidebar,
      id: "12",
      permissionKey: "UserNotification"
    },
    {
      to: "/main/delete-user",
      label: "Deleted Users",
      Icon: assets.Icons.users,
      id: "13",
      permissionKey: "UserNotification"
    },
  ]);

  const [mainLinks, setMainLinks] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {

    let navLinks = [...navLink];

    let finalLinks = [];

    navLinks.forEach(key => {
      if(key?.permissionKey){
        let sideBarPermisions = getSideBarPermissions(key?.permissionKey);
        if (sideBarPermisions) {
          finalLinks.push(key);
        }
      }else{
        finalLinks.push(key);
      }
      
    });



    // navLinks.push({
    //   to: "/auth/login",
    //   label: "Log out",
    //   Icon: assets.Icons.log_out,
    //   id: "0",
    // });

    setMainLinks([...finalLinks]);
  }, [navLink]);

  const dispatch = useDispatch();


  const onClickNav = async (label) => {
    if (label === "Log out") {
      navigate("/auth/login");
      dispatch(saveRememberMe(false));
      dispatch(saveUserData(null))
    }
  };

  const location = useLocation();

  return (
    <div id="sideBar" className="side-bar-container">
      <div className="item-side-bar">
        {mainLinks.map(({ to, label, Icon, ActiveIcon }, index) => {
          return (
            <NavLink
              to={to}
              onClick={() => onClickNav(label)}
              style={{ textDecoration: "none" }}
              key={`${index}`}
            >
              <div
                className={`
                ${location.pathname === to
                    ? "active-sidebar-item"
                    : "in-active-sidebar-item"
                  }
                `}
              >
                <div className="side-bar-list">
                  <span>
                    <img
                      alt={location.pathname}
                      src={Icon}
                      className={`sidebar-icon
                        ${location.pathname === to
                          ? "active-icon"
                          : "in-active-icon"
                        }`}
                    />
                  </span>
                  {
                    <span
                      className={`${location.pathname === to
                          ? "active-label"
                          : "in-active-label"
                        } 
                      `}
                    >
                      {label}
                    </span>
                  }
                </div>
              </div>
            </NavLink>
          );
        })}
        <div style={{
          marginTop: 10
        }}>
        <NavLink
          to="/auth/login"
          onClick={() => onClickNav("Log out")}
          style={{ textDecoration: "none" }}
        >
          <div className="logout-side-bar-list ">
            <span>
              <img
                alt="Log Out"
                src={assets.Icons.log_out}
                className="sidebar-icon"
              />
            </span>
            <span className="in-active-label">Log Out</span>
          </div>
        </NavLink>
        </div>
        <hr className="text-white"></hr>
        <img src={assets.Images.logo} alt="logo" className="side-bar-logo" onClick={() => navigate('/main/dashboard')} />
      </div>
      <div className="bottom-content">
        
        
      </div>
    </div>
  );
};

export default Sidebar;
