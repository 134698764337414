import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import notificationSlice from "./notificationSlice";
import staffSlice from "./staffSlice";
import dropdownSlice from "./dropdownSlice";
import privacyTermsSlice from "./privacyTermsSlice";
import badgesSlice from "./badgesSlice";
import tutorialSlice from "./tutorialSlice";
import selfCareSlice from "./selfCareSlice";
import customerCareSlice from "./customerCareSlice";
import dashboardSlice from "./dashboardSlice";
import userSlice from "./userSlice";
import plansSlice from "./plansSlice";
import subscriptionSlice from "./subscriptionSlice";
import paymentSlice from "./paymentSlice";
import appConfigsSlice from "./appConfigsSlice";
import deeplinkSlice from "./deeplinkSlice";

const reducers = combineReducers({
    auth: authSlice,
    notification: notificationSlice,
    staff: staffSlice,
    dropdown: dropdownSlice,
    privacyTerms: privacyTermsSlice,
    badges: badgesSlice,
    tutorials: tutorialSlice,
    selfCare: selfCareSlice,
    customerCare: customerCareSlice,
    dashboard: dashboardSlice,
    user: userSlice,
    plans: plansSlice,
    subscriptions: subscriptionSlice,
    payment: paymentSlice,
    appConfigs: appConfigsSlice,
    deeplinks: deeplinkSlice
});

export default reducers;